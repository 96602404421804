import React from 'react';
import {
    Chip,
} from "@mui/material";
import './product_card_component.css';
import Product from "../../types/Product";

function ProductCard(product: Product) {
    return (
        <div className={'itemContainerTotal'}>
            <div className={'itemContainer'}>
                <div className={'itemSectionContent'}>
                    <div className={'itemSectionInfo'}>
                        {product.advertisement != null && product.advertisement.length > 0 ? <div className={'itemAdvertisement'}>
                            {product.advertisement.map(advertisement => (
                                <div key={product.productId+advertisement.value}>
                                    { advertisement.color === "error" ?
                                        <Chip className={'AdvertisementChip'}
                                              color= "error"
                                              label={ advertisement.value}
                                              size="small"/>
                                    : advertisement.color === "warning" ?
                                        <Chip className={'AdvertisementChip'}
                                              color= "warning"
                                              label={ advertisement.value}
                                              size="small"/>
                                    :   <Chip className={'AdvertisementChip'}
                                              color= "default"
                                              label={ advertisement.value}
                                              size="small"/>
                                    }
                                </div>
                            ))}
                        </div> : null}
                        <div className={'itemTitle'}>
                            <span className={'itemTitleText'}>{product.name} {product.quantity === null ? "" : product.quantity} {product.unit === null ? "" : product.unit}
                            </span>
                        </div>
                        <div>
                            <div className={'itemDescription'}>{product.description}</div>
                            <div className={'itemDescription'}>{product.description2}</div>
                        </div>
                    </div>
                </div>
                <div className="price-container">
                    <span className="itemPrice">${product.price.toLocaleString('es-ES', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2,
                        localeMatcher: 'lookup' })}</span>
                </div>
            </div>
        </div>
    );
}

export default ProductCard;
