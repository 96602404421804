import './header_business.css'
import React, {useState} from "react";
import {Business} from "../../types/Business";

function Header(businessInfo: Business) {

    const [imageSrcLogo, setImageSrcLogo] = useState(businessInfo.urlLogo);
    const [imageSrcHeader, setImageSrcHeader] = useState(businessInfo.urlHeader);

    const handleImageErrorLogo = () => {
        setImageSrcLogo("/images/generic_logo.png");
    };

    const handleImageErrorHeader = () => {
        setImageSrcHeader("/images/generic_header.jpeg");
    };

    return (
        <header className="header">
            <img src={imageSrcHeader} onError={handleImageErrorHeader} alt="Cover" className="header__cover-image" />
            <div className="header__content">
                <img src={imageSrcLogo} onError={handleImageErrorLogo} alt="Logo" className="header__logo" />
                <h1 className="header__title">{businessInfo.name}</h1>
            </div>
        </header>
    );
}

export default Header;