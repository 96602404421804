import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/home/home_page";
import BusinessProfilePage from "./pages/businessProfile/businessProfile_page";
import './fonts/fonts.css';

function App() {

  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/businessProfile/:businessId" element={<BusinessProfilePage/>}/>
        </Routes>
      </BrowserRouter>
  );
}

export default App;
