import {useEffect, useState} from "react";
import './home_page.css'
import Loading from "../loading/loading_page";
import ErrorPage from "../error/error_page";
import BusinessCard from "../../components/businessCard/business_card_component";
import {Link} from "react-router-dom";
import api from "../../api/api";
import HeaderMercado from "../../components/headerMercado/header_mercado";
import {Business} from "../../types/Business";

function HomePage() {
    const [businessList, setBusinessList] = useState<Business[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const fetch = async () => {
            return await api.getBussinesList();
        }

        fetch()
            .then(data => {
                setBusinessList(data.business);
                setLoading(false);
            })
            .catch(error => {
                setError(new Error(error.toString()));
                setLoading(false);
        });
    }, []);

    if (loading) {
        return <Loading/>;
    }

    if (error) {
        return <ErrorPage/>;
    }

    return (
        <div className={'containerHome'}>
            <HeaderMercado/>
            <ul className={'businessList'}>
                {businessList.map(businessItem => (
                    <li key={businessItem.businessId} >
                        <Link className={'link'} to={`/businessProfile/${businessItem.businessId}`}>
                            <BusinessCard
                                name={businessItem.name}
                                category={businessItem.category}
                                businessNumber={businessItem.businessNumber}
                                nave={businessItem.nave}
                                urlLogo={businessItem.urlLogo}
                                urlHeader={businessItem.urlHeader}
                                ratings={businessItem.ratings}
                                numberOfRatings={businessItem.numberOfRatings}
                                schedule={businessItem.schedule}
                                methodOfPayment={businessItem.methodOfPayment}
                                businessId={businessItem.businessId}/>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default HomePage;