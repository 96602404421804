import './business_card_component.css'
import React, {useState} from "react";
import methodOfPayments from "../../utils/enums/methodOfPayment";
import {Business} from "../../types/Business";


function BusinessCard(business: Business ) {
    const [imageSrc, setImageSrc] = useState(business.urlLogo);

    const handleImageError = () => {
        setImageSrc("/images/generic_logo.png");
    };

    return (
        <div>
            <div className={"containerBusiness"}>
                <div className={"advertisementsBusiness"}>{business.category}</div>
                <div className={"businessCardStructure"}>
                    <div className="imageBusinessContainer">
                        <div className="imageBusiness">
                                <img className={"imageValue"}
                                    src={imageSrc}
                                     onError={handleImageError}
                                     alt="logo"/>
                        </div>
                    </div>
                    <div className="contentBusinessContainer">
                        <div className="contentBusinessTitle">
                            <div className="contentBusinessTitleText">{business.name}</div>
                        </div>
                        <div className="contentBusinessLocation">
                            <div className="contentBusinessLocationText">Nave: {business.nave} - Nro local: {business.businessNumber}</div>
                        </div>
                        { business.methodOfPayment !== null && business.methodOfPayment.length > 0 ?
                            <div className="contentBusinessMethodOfPayment">
                                {business.methodOfPayment.map((method) => (
                                    <div key={business.businessId+method} className="contentBusinessMethodOfPayment01">
                                        <span>{methodOfPayments[method]}</span>
                                    </div>
                                ))}
                            </div> : null
                        }
                    </div>
                    { business.ratings !== null && business.ratings > 0 && business.numberOfRatings > 20 ?
                        <div className="ratingsBusinessContainer">
                            <div className="ratingsBusinessContent">
                                <span>{business.ratings} ⭐</span>
                            </div>
                        </div>
                        : null
                    }

                </div>
            </div>
        </div>
    );
}

export default BusinessCard;
