import './footer_app.css'

function Footer() {

    return (
        <footer>
            <div className="redes-sociales">
                <a href="https://www.instagram.com/prensaensenada/?hl=es">
                    <img className={"item_redes-sociales"} src="/images/instagram.png" alt="Instagram"/>
                </a>
                <a href="https://twitter.com/prensaensenada?lang=es">
                    <img className={"item_redes-sociales"} src="/images/twitter.png" alt="Twitter"/>
                </a>
                <a href="https://www.facebook.com/prensamunicipalidadensenada">
                    <img className={"item_redes-sociales"} src="/images/facebook.png" alt="Facebook"/>
                </a>
            </div>
            <div>
                <hr className="linea-separadora"/>
            </div>
            <div className="container_texto">
                <p>Desarrollado por <a href={"https://mutar.ar/"}>@mutar</a></p>
                <p>Todos los derechos reservados a la</p>
                <p>Municipalidad de Ensenada</p>
            </div>
        </footer>
    );
}

export default Footer;