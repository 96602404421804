import './header_mercado.css'
import React from "react";

function HeaderMercado() {

    return (
        <header className="header_mercado">
            <img src={"/images/generic_header.jpeg"}  alt="Cover" className="header__cover-image_mercado" />
            <div className="header__content_mercado">
                <img src={"/images/generic_logo.png"} alt="Logo" className="header__logo_mercado" />
                <div className="header__content_central_mercado">
                    <h1 className="header__title_mercado_mercado">{"Mercado Municipal Ensenada"}</h1>
                    <p className="header__horarios_mercado">
                        <div>Lunes a Sábados 9:30hs a 18:30hs</div>
                        <div>Av. Bossinga e Illia</div>
                    </p>
                </div>
                {/*<div className={"header__location_mercado"}>*/}
                {/*    <img className={"header__location_icon_mercado"} src="/images/icono_ubicacion.png" alt={"location"}/>*/}
                {/*</div>*/}
            </div>
        </header>
    );
}

export default HeaderMercado;