import {useEffect, useState} from "react";
import ProductCard from "../../components/ProductCard/product_card_component";
import './businessProfile.css'
import Product from "../../types/Product";
import Loading from "../loading/loading_page";
import ErrorPage from "../error/error_page";
import Header from "../../components/headerBusiness/header_business";
import api from "../../api/api";
import { useParams } from "react-router-dom";
import Footer from "../../components/footer/footer_app";
import {Business} from "../../types/Business";
import SearchBar from "../../components/SearchBar/search_bar_component";


function BusinessProfile() {
    const [products, setProducts] = useState<Product[]>([]);
    const [businessInfo, setBusinessInfo] = useState<Business>({
        businessNumber: "",
        category: "",
        businessId: "",
        nave: "",
        methodOfPayment: [],
        name: "",
        numberOfRatings: 0,
        ratings: 0,
        schedule: {domingo: "", jueves: "", lunes: "", martes: "", miércoles: "", sábado: "", viernes: ""},
        urlHeader: "",
        urlLogo: ""
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const { businessId } = useParams<{ businessId: string }>();
    const [filtro, setFiltro] = useState<string>('');

    useEffect(() => {
        const fetch = async () => {
            return await api.getBussinesDetail(businessId);
        }
        fetch()
            .then(data => {
                setBusinessInfo(data.business);
                setProducts(data.products);
                setLoading(false);
            })
            .catch(error => {
                setError(new Error(error.message));
                setLoading(false);
            });
    }, []);

    const handleFiltroChange = (nuevoFiltro: string) => {
        setFiltro(nuevoFiltro);
    };

    const productosFiltrados = products.filter((producto) =>
        producto.name.toLowerCase().includes(filtro.toLowerCase())
    );

    if (loading) {
        return <Loading/>;
    }

    if (error) {
        return <ErrorPage/>;
    }

    let categories = new Set(products.map(product => product.category));
    let sortedCategories = Array.from(categories).sort();
    return (
        <div className={'containerHome'}>
            <Header
                name={businessInfo.name}
                category={businessInfo.category}
                businessNumber={businessInfo.businessNumber}
                nave={businessInfo.nave}
                urlLogo={businessInfo.urlLogo}
                urlHeader={businessInfo.urlHeader}
                methodOfPayment={businessInfo.methodOfPayment}
                ratings={businessInfo.ratings}
                numberOfRatings={businessInfo.numberOfRatings}
                schedule={businessInfo.schedule}
                businessId={businessInfo.businessId}/>
            <ul className={'categoryList'}>
                <SearchBar onSearch={handleFiltroChange} />
                {Array.from(sortedCategories).map(category => (
                    <li key={category}>
                        { category != null && category !== "" ? (
                            <h2 className={'itemCategory__title'}>{category}</h2>
                        ) : null}
                        <ul className={'businessList'}>
                            {productosFiltrados
                                .filter(product => product.category === category)
                                .map(product => (
                                    <li key={product.productId} className={'itemBusiness'}>
                                        <ProductCard
                                            businessId={product.businessId}
                                            productId={product.productId}
                                            category={product.category}
                                            name={product.name}
                                            description={product.description}
                                            description2={product.description2}
                                            quantity={product.quantity}
                                            unit={product.unit}
                                            price={product.price}
                                            image={product.image}
                                            advertisement={product.advertisement}
                                            priceDiscount={product.priceDiscount}/>
                                    </li>
                                ))}
                        </ul>
                    </li>
                ))}
            </ul>
            <Footer/>
        </div>
    );
}

export default BusinessProfile;