import React, { useState } from 'react';
import "./search_bar_component.css"

interface SearchBarProps {
    onSearch: (filter: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
    const [filter, setFilter] = useState<string>('');

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newFilter = event.target.value;
        setFilter(newFilter);
        onSearch(newFilter);
    };

    return (
        <div className="search-bar">
            <input
                className="input-search"
                type="text"
                placeholder="Buscar producto"
                value={filter}
                onChange={handleFilterChange}
            />
        </div>
    );
};

export default SearchBar;
