import React from 'react';
import "./error_page.css"

function ErrorPage() {

    return (
        <div className={'errorContainer'}>
            <img className={'errorImage'} src={'/images/error_500.png'}  alt={"imagen error"}/>
        </div>
    );
}

export default ErrorPage;