import React from 'react';
import {CircularProgress} from "@mui/material";
import "./loading_page.css"

function Loading() {

    return (
        <div className={'loadingContainer'}>
            <CircularProgress />
        </div>
    );
}

export default Loading;