import BusinessDetail from "../types/BusinessDetail";
import axios, {AxiosResponse} from "axios";
import {RootBusiness} from "../types/Business";

const BASE_URL = process.env.REACT_APP_API_URL || ``;

async function getBussinesDetail(idBusiness: string | undefined): Promise<BusinessDetail> {
    const response: AxiosResponse<BusinessDetail> = await axios.get(`${BASE_URL}/business/detail/${idBusiness}`);
    return response.data;
}

async function getBussinesList() : Promise<RootBusiness> {
    const response: AxiosResponse<RootBusiness> = await axios.get(`${BASE_URL}/business`);
    return response.data;
}

export default {
    getBussinesDetail,
    getBussinesList
};